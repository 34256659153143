<template>
	<div class="container-layout form px-0">
		<filter-budget-advanced
			:show-user-selector="false"
			:label-expense-details="FormMSG(169, 'Expense details')"
			use-department-for-expense
			hide-supplier-section
			hide-expense-details-department
			hide-expense-details-category
			:hide-process-filter="false"
			:filter-type="4"
			@filter-budget-advanced:click-selection="handleFilterBudgetAdvancedClickSelection"
		/>

		<b-row class="mt-3 hide-on-tablet">
			<b-col md="5">
				<b-form-group class="mb-0">
					<b-input-group v-if="$screen.width >= 992">
						<filterInputSearch id="expense-sheet" @filtered-users="activeFilter" @handle-init-filter="onHandleInitFilter" />
						<!-- <b-form-input v-model="filter" type="text" id="filterInput" :placeholder="this.FormMSG(37, 'Type to Search')" />
						<b-input-group-append class="cursor-pointer">
							<b-input-group-text class="btn-search">
								<Search color="#FFFFFF" :size="16" class="icon" :stroke-width="2.5" v-if="filter.length === 0" />
								<X color="#FFFFFF" :size="16" class="icon" :stroke-width="2.5" @click="filter = ''" v-else />
							</b-input-group-text>
						</b-input-group-append> -->
						</b-input-group-append>
					</b-input-group>
				</b-form-group>
			</b-col>
			<b-col md="7">
				<custom-pagination
					v-if="!haveActiveFilter"
					:total-records="totalRecords"
					:initial-perpage="perPage"
					:initial-offset="offset"
					:initial-limit="limit"
					:filter-type="4"
					@custom-pagination:previous="handleClickLoadMore"
					@custom-pagination:forward="handleClickLoadMore"
					@custom-pagination:change-per-page="handleClickLoadMore"
				/>
			</b-col>
		</b-row>

		<b-row class="mt-3 ml-1">
			<CardListBuilder class="hide-on-desktop" v-if="!$screen.md" :items="expenses" :filter="filter" style="width: 100%" :fields="bgFieldsMobile">
				<template slot="actions" slot-scope="data">
					<div class="mt-2"></div>
				</template>
			</CardListBuilder>
			<b-table
				v-else
				responsive="sm"
				:filter="filter"
				:items="expenseSheets"
				:fields="bgFields"
				style="width: 100%; font-weight: 500"
				sticky-header="700px"
				:head-variant="hv"
				:hover="true"
				bordered
				small
				show-empty
				selectable
				:selectedVariant="selectedColor"
				:select-mode="selectMode"
				:empty-text="FormMSG(265, 'No expense sheets found')"
			>
				<template #cell(processed)="{ item, index }">
					<div>
						<b-form-checkbox
							v-model="item.expense.processed"
							size="lg"
							:value="true"
							:unchecked-value="false"
							@change="handleChangeProcess($event, item, index)"
						/>
					</div>
				</template>
				<template #cell(details)="data">
					<div>
						<button class="btn-transparent" @click="handleClickDetail(data.item.expense)">
							<component :is="getLucideIcon('Eye')" :size="16" color="#06263E" />
						</button>
					</div>
				</template>
				<template #cell(validated)="data">
					<div class="wrap-status">
						<div :class="`status ${getStatusColor(data.item.expense.validated)}`" style="font-size: 0.7rem">
							{{ getLabelStatus(data.item.expense.validated) }}
						</div>
					</div>
				</template>
			</b-table>
		</b-row>
		<script-export-modal
			v-model="isScriptExpenseReportOpen"
			:items="scripts"
			:custom="customReport"
			export-type="expense"
			@script-export-modal:loading="handleLoading"
			@script-export-modal:closed="expenseRerpotOnClosed"
		/>
	</div>
</template>

<script>
import FilterBudgetAdvanced from '@/components/Budget/FilterBudgetAdvanced';
import CustomPagination from '@/components/Budget/CustomPagination';
import languageMessages from '@/mixins/languageMessages';
import GlobalMixin from '@/mixins/global.mixin';
import { Search, X } from 'lucide-vue';
import moment from 'moment';
import { rendCurrency, rendKgCo2 } from '~helpers';
import { mapActions } from 'vuex';
import { getTextFromMenuNumberAndMenuValue } from '@/cruds/language.crud';
import { getFileExtension } from '@/shared/helpers';
import _ from 'lodash';
import { expenseReport } from '@/cruds/export.crud';
import { getScripts } from '@/cruds/script.crud';
import filterInputSearch from '../users/filterInput.vue';
import ScriptExportModal from '@/components/Export/ScriptExportModal';
import { FILENAME_REPORT, OUTPUT_FORMAT_EXPORT } from '@/shared/constants';
import { store } from '@/store';
import { changeStatusOfProcess } from '@/cruds/budget.crud';

const validatedText = async (validated) => {
	let value = null;
	await new Promise((resolve, reject) => {
		setTimeout(() => {
			resolve(getTextFromMenuNumberAndMenuValue(1008, validated));
			reject(false);
		}, 150);
	}).then((data) => {
		value = data;
	});

	return value;
};

const getTypeText = async (type) => {
	let value = null;
	await new Promise((resolve, reject) => {
		setTimeout(() => {
			resolve(getTextFromMenuNumberAndMenuValue(1003, type));
			reject(false);
		}, 150);
	}).then((data) => {
		value = data;
	});

	return value;
};

// const validatedColor = (validated) => {
// 	let retval = 'info';
// 	if (validated == 1) {
// 		retval = 'primary';
// 	} else if (validated == 2) {
// 		retval = 'warning';
// 	} else if (validated == 8) {
// 		retval = 'success';
// 	} else if (validated == 4 || validated == 16) {
// 		retval = 'danger';
// 	}
// 	return retval;
// };

const statusClass = (validated) => {
	let value = 'not-submitted';
	if (validated === 1) {
		value = 'info';
	} else if (validated === 2) {
		value = 'pending';
	} else if (validated === 8) {
		value = 'validated';
	} else if (validated === 4 || validated === 16) {
		value = 'refused';
	}
	return value;
};

const getSubCategoryText = async (id) => {
	let value = null;
	await new Promise((resolve, reject) => {
		setTimeout(() => {
			resolve(getTextFromMenuNumberAndMenuValue(905, id));
			reject(false);
		}, 150);
	}).then((data) => {
		value = data;
	});

	return value;
};

export default {
	name: 'ExpenseSheets',
	props: {
		budgetExpenseSheetData: { type: Array, required: true },
		totalRecords: { type: Number, required: 0 }
	},
	mixins: [languageMessages, GlobalMixin],
	components: {
		FilterBudgetAdvanced,
		Search,
		X,
		CustomPagination,
		ScriptExportModal,
		filterInputSearch
	},
	data() {
		return {
			hv: 'dark',
			selectMode: 'single',
			selectedColor: 'primary',
			filter: '',
			expenseSheets: [],
			perPage: 100,
			haveActiveFilter: false,
			offset: 0,
			limit: 100,
			isScriptExpenseReportOpen: false,
			scripts: [],
			customReport: null,
			scriptType: 4
		};
	},
	watch: {
		budgetExpenseSheetData: {
			handler(newVal) {
				this.expenseSheets = newVal;
			},
			immediate: true,
			deep: true
		}
	},
	computed: {
		bgFields() {
			let field = [
				{
					key: 'processed',
					label: this.FormMSG(524, 'Processed'),
					sortable: false,
					class: 'text-center'
				},
				{
					key: 'expense.date',
					label: this.FormMSG(17, 'Date'),
					formatter: (value) => {
						return moment(value).format('DD/MM/YYYY');
					},
					sortable: true,
					class: 'text-left'
				},
				{
					key: 'expense.id',
					label: this.FormMSG(218, 'ID'),
					sortable: true,
					formatter: (value) => {
						return `#${value}`;
					},
					class: 'text-left'
				},
				{
					key: 'expense.description',
					label: this.FormMSG(219, 'Description'),
					sortable: true,
					class: 'text-left'
				},
				{
					key: 'expense.type',
					label: this.FormMSG(220, 'Type'),
					sortable: true,
					formatter: (value) => {
						return this.getLabelTypeExpense(value);
					},
					class: 'text-left'
				},
				{
					key: 'fullName',
					label: this.FormMSG(221, 'Full Name'),
					sortable: true,
					formatter: (value, key, item) => {
						return `${item.expense.user.name} ${item.expense.user.firstName}`;
					},
					class: 'text-left'
				},
				{
					key: 'department',
					label: this.FormMSG(222, 'Department & Function'),
					sortable: true,
					formatter: (value, key, item) => {
						return `${item.expense.departmentName} - ${item.expense.functionName}`;
					},
					class: 'text-left'
				},
				{
					key: 'validated',
					label: this.FormMSG(223, 'Status'),
					sortable: true,
					class: 'text-center'
				},
				{
					key: 'expense.amountTotal',
					label: this.FormMSG(224, 'Total amount'),
					formatter: (value) => {
						return rendCurrency(value);
					},
					sortable: true,
					class: 'text-right'
				},
				{
					key: 'expense.kgCoTwo',
					label: this.FormMSG(225, 'CO2'),
					formatter: (value) => {
						return rendKgCo2(value);
					},
					sortable: true,
					class: 'text-right'
				},
				{
					key: 'details',
					label: this.FormMSG(226, 'Details'),
					sortable: true,
					class: 'text-center'
				}
			];

			return field;
		}
	},
	methods: {
		...mapActions({
			setFocusExpenseItem: 'expenses/setFocusExpenseItem',
			getCurExpense: 'expenses/getCurExpense'
		}),
		activeFilter(keyword) {
			this.haveActiveFilter = true
			this.$emit('activate-filter-by-keyword', keyword);
		},
		onHandleInitFilter() {
			this.filter = '';
			this.haveActiveFilter = false
			this.$emit('activate-filter-by-keyword', null);
		},
		async handleChangeProcess(payload, item, index) {
			await changeStatusOfProcess(+item.expense.id, 1, payload);
		},

		async handleClickDetail(expense) {
			const item = {
				...expense,
				validatedClass: await validatedText(expense.validated),
				statusClass: statusClass(expense.validated),
				typeString: await getTypeText(expense.type),
				validatedStatus: await validatedText(expense.validated),
				subCategoryName: await getSubCategoryText(expense.subCategory)
			};
			this.getCurExpense(parseInt(expense.id, 10));
			this.setFocusExpenseItem(item);
			this.$router.replace({
				name: 'budget-expense-sheet-details',
				query: {
					fromBudget: true
				},
				params: {
					id: expense.id
				}
			});
		},
		handleClickLoadMore({ offset, limit, perPage }) {
			this.offset = offset;
			this.limit = limit;
			this.perPage = perPage;

			this.$emit('budget-expense-sheets:load-more', {
				offset,
				limit,
				perPage: perPage
			});
		},
		getLabelTypeExpense(value) {
			let name = '';
			const labelTypes = [
				{ value: 0, text: this.FormMSG(327, 'Ticket') },
				{ value: 1, text: this.FormMSG(328, 'Invoice') },
				{ value: 2, text: this.FormMSG(329, 'Travel') }
			];
			const types = labelTypes.filter((lT) => lT.value === value);
			if (types.length > 0) {
				for (const typeName of types) {
					return (name = typeName.text.toUpperCase());
				}
			}
			return name;
		},
		getLabelStatus(value) {
			return this.GetTextFromMenuNumberAndMenuValue(1008, value);
		},
		getStatusColor(validated) {
			let retval = 'not-submitted';
			if (validated == 1) {
				retval = 'info';
			} else if (validated == 2) {
				retval = 'pending';
			} else if (validated == 8) {
				retval = 'validated';
			} else if (validated == 4 || validated == 16) {
				retval = 'refused';
			}
			return retval;
		},
		emitLoading(value) {
			this.$emit('budget-expenses:loading', value);
		},
		async handleFilterBudgetAdvancedClickSelection(oPayload) {
			this.emitLoading(true);
			const payload = _.omit(oPayload, this.removeFromReportKeys);

			this.scripts = await getScripts(store.state.myProject.licenseID, store.state.myProject.id, this.scriptType, true);

			let script = { id: 0, fileName: null, ext: 'xlsx' };

			if (this.scripts.length > 1) {
				this.customReport = payload;
				this.isScriptExpenseReportOpen = true;
				this.emitLoading(false);
			} else {
				this.customReport = null;

				if (this.scripts.length === 1) {
					script = {
						id: this.scripts[0].id,
						fileName: this.scripts[0].customExportFileName,
						ext: this.getExtensionScript(
							OUTPUT_FORMAT_EXPORT.at(this.scripts[0].outputFileFormat),
							this.scripts[0].customExtension,
							getFileExtension(this.scripts[0].fileName)
						)
					};

					let fileName = this.getFileNameScript(FILENAME_REPORT.REPORT_EXPENSE, script.id, script.fileName);

					await expenseReport(payload, script.id)
						.then((result) => {
							this.forceDownload(result, fileName + '.' + script.ext);
						})
						.catch((e) => {
							console.error(e);
							this.emitLoading(false);
						})
						.finally(() => {
							this.emitLoading(false);
						});
				} else {
					this.createToastForMobile(
						this.FormMSG(330, 'Warning'),
						this.FormMSG(331, 'Please create at least one script template to execute correctly this export.'),
						'',
						'warning'
					);
					this.emitLoading(false);
				}
			}
		},
		expenseRerpotOnClosed() {
			this.isScriptExpenseReportOpen = false;
			this.emitLoading(false);
		},
		handleLoading(payload) {
			if (payload) {
				this.emitLoading(false);
			}
		}
	}
};
</script>

<style scoped></style>
