var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container-layout form px-0" },
    [
      _c("filter-budget-advanced", {
        attrs: {
          "show-user-selector": false,
          "label-expense-details": _vm.FormMSG(169, "Expense details"),
          "use-department-for-expense": "",
          "hide-supplier-section": "",
          "hide-expense-details-department": "",
          "hide-expense-details-category": "",
          "hide-process-filter": false,
          "filter-type": 4,
        },
        on: {
          "filter-budget-advanced:click-selection":
            _vm.handleFilterBudgetAdvancedClickSelection,
        },
      }),
      _c(
        "b-row",
        { staticClass: "mt-3 hide-on-tablet" },
        [
          _c(
            "b-col",
            { attrs: { md: "5" } },
            [
              _c(
                "b-form-group",
                { staticClass: "mb-0" },
                [
                  _vm.$screen.width >= 992
                    ? _c(
                        "b-input-group",
                        [
                          _c("filterInputSearch", {
                            attrs: { id: "expense-sheet" },
                            on: {
                              "filtered-users": _vm.activeFilter,
                              "handle-init-filter": _vm.onHandleInitFilter,
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { md: "7" } },
            [
              !_vm.haveActiveFilter
                ? _c("custom-pagination", {
                    attrs: {
                      "total-records": _vm.totalRecords,
                      "initial-perpage": _vm.perPage,
                      "initial-offset": _vm.offset,
                      "initial-limit": _vm.limit,
                      "filter-type": 4,
                    },
                    on: {
                      "custom-pagination:previous": _vm.handleClickLoadMore,
                      "custom-pagination:forward": _vm.handleClickLoadMore,
                      "custom-pagination:change-per-page":
                        _vm.handleClickLoadMore,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mt-3 ml-1" },
        [
          !_vm.$screen.md
            ? _c("CardListBuilder", {
                staticClass: "hide-on-desktop",
                staticStyle: { width: "100%" },
                attrs: {
                  items: _vm.expenses,
                  filter: _vm.filter,
                  fields: _vm.bgFieldsMobile,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "actions",
                      fn: function (data) {
                        return [_c("div", { staticClass: "mt-2" })]
                      },
                    },
                  ],
                  null,
                  false,
                  24091394
                ),
              })
            : _c("b-table", {
                staticStyle: { width: "100%", "font-weight": "500" },
                attrs: {
                  responsive: "sm",
                  filter: _vm.filter,
                  items: _vm.expenseSheets,
                  fields: _vm.bgFields,
                  "sticky-header": "700px",
                  "head-variant": _vm.hv,
                  hover: true,
                  bordered: "",
                  small: "",
                  "show-empty": "",
                  selectable: "",
                  selectedVariant: _vm.selectedColor,
                  "select-mode": _vm.selectMode,
                  "empty-text": _vm.FormMSG(265, "No expense sheets found"),
                },
                scopedSlots: _vm._u([
                  {
                    key: "cell(processed)",
                    fn: function ({ item, index }) {
                      return [
                        _c(
                          "div",
                          [
                            _c("b-form-checkbox", {
                              attrs: {
                                size: "lg",
                                value: true,
                                "unchecked-value": false,
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.handleChangeProcess(
                                    $event,
                                    item,
                                    index
                                  )
                                },
                              },
                              model: {
                                value: item.expense.processed,
                                callback: function ($$v) {
                                  _vm.$set(item.expense, "processed", $$v)
                                },
                                expression: "item.expense.processed",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                  {
                    key: "cell(details)",
                    fn: function (data) {
                      return [
                        _c("div", [
                          _c(
                            "button",
                            {
                              staticClass: "btn-transparent",
                              on: {
                                click: function ($event) {
                                  return _vm.handleClickDetail(
                                    data.item.expense
                                  )
                                },
                              },
                            },
                            [
                              _c(_vm.getLucideIcon("Eye"), {
                                tag: "component",
                                attrs: { size: 16, color: "#06263E" },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]
                    },
                  },
                  {
                    key: "cell(validated)",
                    fn: function (data) {
                      return [
                        _c("div", { staticClass: "wrap-status" }, [
                          _c(
                            "div",
                            {
                              class: `status ${_vm.getStatusColor(
                                data.item.expense.validated
                              )}`,
                              staticStyle: { "font-size": "0.7rem" },
                            },
                            [
                              _vm._v(
                                "\n\t\t\t\t\t\t" +
                                  _vm._s(
                                    _vm.getLabelStatus(
                                      data.item.expense.validated
                                    )
                                  ) +
                                  "\n\t\t\t\t\t"
                              ),
                            ]
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
        ],
        1
      ),
      _c("script-export-modal", {
        attrs: {
          items: _vm.scripts,
          custom: _vm.customReport,
          "export-type": "expense",
        },
        on: {
          "script-export-modal:loading": _vm.handleLoading,
          "script-export-modal:closed": _vm.expenseRerpotOnClosed,
        },
        model: {
          value: _vm.isScriptExpenseReportOpen,
          callback: function ($$v) {
            _vm.isScriptExpenseReportOpen = $$v
          },
          expression: "isScriptExpenseReportOpen",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }