<template>
	<b-modal
		header-class="header-class-modal-doc-package"
		size="lg"
		cancel-variant="outline-primary"
		:title="FormMSG(2001, 'Budget category detail')"
		button-size="md"
		:ok-title="FormMSG(201, 'Save')"
		:cancel-title="FormMSG(246, 'Cancel')"
		ok-variant="primary"
		v-model="isOpen"
		:no-close-on-backdrop="true"
		@cancel="emitEventClose"
		@show="getCategories"
		@hidden="emitEventClose"
		@ok.prevent="handleSubmit"
	>
		<div class="form">
			<b-row>
				<b-col xl="6" lg="6" md="6" sm="12">
					<b-form-group :label="FormMSG(141, 'Category name')">
						<b-form-input v-model="dataToEdit.categoryName" :placeholder="FormMSG(141, 'Category name')" />
					</b-form-group>
				</b-col>
				<b-col xl="6" lg="6" md="6" sm="12">
					<b-row>
						<b-col :cols="dataToEdit.contentType === 0 ? 6 : 12">
							<b-form-group :label="FormMSG(142, 'Cost center')">
								<b-form-input v-model="dataToEdit.costCenter" :placeholder="FormMSG(142, 'Cost center')" />
							</b-form-group>
						</b-col>
						<b-col v-if="dataToEdit.contentType === 0" cols="6" class="pt-28">
							<b-form-checkbox v-model="dataToEdit.visibleInApp" size="lg" :value="true" :unchecked-value="false">
								{{ FormMSG(143, 'Visible for crew') }}
							</b-form-checkbox>
						</b-col>
					</b-row>
				</b-col>
			</b-row>
			<b-row>
				<b-col xl="4" lg="4" md="4" sm="12">
					<b-form-group :label="FormMSG(144, 'Parent category')">
						<treeselect
							v-model="dataToEdit.parentCategory"
							:multiple="false"
							:options="mapCategories"
							:disable-branch-nodes="true"
							:clearable="false"
							:placeholder="FormMSG(297, 'Select a category')"
							:disabled="action === 'add-sub' || action === 'edit'"
							@select="handleSelectParentCategory"
						>
							<div slot="option-label" slot-scope="{ node }">
								<div class="treeselect-label" :title="node.label">
									{{ node.label }}
								</div>
							</div>
						</treeselect>
					</b-form-group>
				</b-col>
				<b-col xl="4" lg="4" md="4" sm="12">
					<b-form-group :label="FormMSG(169, 'Type')">
						<b-form-select
							v-model="dataToEdit.contentType"
							:options="contentTypeMenu"
							:disabled="dataToEdit.departmentNumber === 0 || budget.budgetDetailIsLinkedToFunctionId > 0 || action === 'add-sub'"
						/>
					</b-form-group>
				</b-col>
				<b-col v-if="dataToEdit.contentType === 0" xl="4" lg="4" md="4" sm="12">
					<b-form-group :label="FormMSG(145, 'CO2 Category')">
						<b-form-select v-model="dataToEdit.co2Category" :options="co2CategoriesMenu" />
					</b-form-group>
				</b-col>
			</b-row>
			<b-row>
				<b-col xl="6" lg="6" md="6" sm="12">
					<b-form-group :label="FormMSG(146, 'Budget amount')">
						<b-input-group>
							<b-form-input v-model="dataToEdit.budgetAmount" :disabled="budgetIsLocked" @input="handleInputBudgetAmount" />
							<b-input-group-prepend>
								<b-input-group-text>
									<Euro :size="16" />
								</b-input-group-text>
							</b-input-group-prepend>
						</b-input-group>
					</b-form-group>
				</b-col>
				<b-col xl="6" lg="6" md="6" sm="12">
					<b-form-group :label="FormMSG(147, 'Estimated final cost (EFC)')">
						<b-input-group>
							<b-form-input v-model="dataToEdit.efc" />
							<b-input-group-prepend>
								<b-input-group-text>
									<Euro :size="16" />
								</b-input-group-text>
							</b-input-group-prepend>
						</b-input-group>
					</b-form-group>
				</b-col>
			</b-row>
			<b-row>
				<b-col xl="6" lg="6" md="6" sm="12">
					<b-form-group :label="FormMSG(148, 'Initial spent')">
						<b-input-group>
							<b-form-input v-model="dataToEdit.initialSpent" />
							<b-input-group-prepend>
								<b-input-group-text>
									<Euro :size="16" />
								</b-input-group-text>
							</b-input-group-prepend>
						</b-input-group>
					</b-form-group>
				</b-col>
			</b-row>
			<b-row>
				<b-col>
					<p>
						{{ FormMSG(149, '* To set additional option on the category, please go under Configuration/Project - Tab Budget categories.') }}
					</p>
				</b-col>
			</b-row>
		</div>
	</b-modal>
</template>

<script>
import languageMessages from '@/mixins/languageMessages';
import InputSelectDoconomy from '@/components/Doconomy/InputSelectDoconomy';
import { Percent, Search, X as Reset, Euro } from 'lucide-vue';
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import CurrencySvg from '@/components/Svg/Currency';
import isSingleProjectMixin from '@/mixins/isSingleProject.mixin';
import { fieldBudgetDetail } from '@/cruds/categorie.crud';
import { getBudgetHierarchicalCategoryJson } from '@/cruds/budget.crud';
import { removeAttributeTree } from '@/shared/utils';
import { addBudgetDetail, updateBudgetDetail } from '@/cruds/categorie.crud';
import GlobalMixin from '@/mixins/global.mixin';
import _ from 'lodash';
import { isNil } from '@/shared/utils';
import { store } from '@/store';

const ACTIONS = ['add-parent', 'add-sub', 'edit'];

export default {
	name: 'BudgetCategorieModal',

	mixins: [languageMessages, isSingleProjectMixin, GlobalMixin],

	props: {
		open: { type: Boolean, default: false },
		budget: { type: Object, default: null, required: false },
		action: { type: String, default: 'add-parent', required: false, validator: (v) => ACTIONS.includes(v) }
		// addSubCategory: { type: Boolean, default: false, required: false },
		// addParentCategory: { type: Boolean, default: false, required: false }
	},

	components: {
		InputSelectDoconomy,
		CurrencySvg,
		Treeselect,
		Percent,
		Search,
		Reset,
		Euro
	},

	data() {
		return {
			treeCatsSelect: [],
			dataToEdit: {
				co2Category: 0,
				visibleInApp: true,
				costCenter: '',
				categoryName: '',
				budgetAmount: 0,
				efc: 0,
				initialSpent: 0,
				parentCategory: 0,
				departmentNumber: 0,
				contentType: 0
			},
			budgetIsLocked: false,
			mapCategories: []
		};
	},

	watch: {
		budget: {
			handler(newVal) {
				this.budgetIsLocked = store.state.myProject.budgetLocked;
				if (!isNil(newVal)) {
					if (this.action === 'edit') {
						this.dataToEdit.co2Category = newVal.budgetDetailCo2Category;
						this.dataToEdit.visibleInApp = newVal.budgetDetailAppChoice;
						this.dataToEdit.costCenter = newVal.cost_center;
						this.dataToEdit.categoryName = newVal.budgetDetailDescription;
						this.dataToEdit.budgetAmount = newVal.budgetDetailInitialBudget;
						this.dataToEdit.efc = newVal.budgetDetailInitial;
						this.dataToEdit.initialSpent = newVal.budgetDetailStartOfPeriodValue;
						this.dataToEdit.parentCategory = newVal.budgetDetailParentId;
						this.dataToEdit.departmentNumber = newVal.department;
						this.dataToEdit.contentType = newVal.contentType;
					}

					if (this.action === 'add-sub') {
						this.dataToEdit.parentCategory = newVal.budgetDetailId;
						this.dataToEdit.contentType = newVal.contentType;
					}
				}
			},
			immediate: true,
			deep: true
		}
	},

	computed: {
		isOpen: {
			get() {
				return this.open;
			},
			set(val) {
				return val;
			}
		},
		contentTypeMenu() {
			return [
				{
					value: 0,
					text: this.FormMSG(1000, 'Expense')
				},
				{
					value: 1,
					text: this.FormMSG(1001, 'Salary')
				}
			];
		},
		co2CategoriesMenu() {
			return this.FormMenu(1334);
		}
	},

	async created() {},

	methods: {
		handleInputBudgetAmount(payload) {
			if (!this.budgetIsLocked) {
				if (this.budget.budgetDetailVariance === 0) {
					this.dataToEdit.efc = payload;
				}
			}
		},
		handleSelectParentCategory(payload) {
			this.dataToEdit.departmentNumber = payload.data.department;
		},
		async handleSubmit() {
			if (this.action === 'add-parent') {
				await addBudgetDetail(this.dataToEdit.departmentNumber, this.dataToEdit.parentCategory, {
					estimatedFinalCost: parseInt(this.dataToEdit.efc, 10),
					costCenter: this.dataToEdit.costCenter,
					coTwoCategory: this.dataToEdit.co2Category,
					description: this.dataToEdit.categoryName,
					initialBudget: parseInt(this.dataToEdit.budgetAmount, 10),
					startOfPeriodValue: parseInt(this.dataToEdit.initialSpent, 10),
					appChoice: this.dataToEdit.visibleInApp
				});

				this.createToastForMobile(this.FormMSG(58, 'Success'), this.FormMSG(59, 'Category created successfully'));
			} else if (this.action === 'edit') {
				await updateBudgetDetail(this.budget.budgetDetailId, {
					estimatedFinalCost: parseInt(this.dataToEdit.efc, 10),
					costCenter: this.dataToEdit.costCenter,
					coTwoCategory: this.dataToEdit.co2Category,
					description: this.dataToEdit.categoryName,
					startOfPeriodValue: parseInt(this.dataToEdit.initialSpent, 10),
					initialBudget: parseInt(this.dataToEdit.budgetAmount, 10),
					appChoice: this.dataToEdit.visibleInApp
				});

				this.createToastForMobile(this.FormMSG(58, 'Success'), this.FormMSG(60, 'Category updated successfully'));
			} else if (this.action === 'add-sub') {
				await addBudgetDetail(this.budget.department, this.dataToEdit.parentCategory, {
					estimatedFinalCost: parseInt(this.dataToEdit.efc, 10),
					costCenter: this.dataToEdit.costCenter,
					coTwoCategory: this.dataToEdit.co2Category,
					description: this.dataToEdit.categoryName,
					initialBudget: parseInt(this.dataToEdit.budgetAmount, 10),
					startOfPeriodValue: parseInt(this.dataToEdit.initialSpent, 10),
					appChoice: this.dataToEdit.visibleInApp
				});

				this.createToastForMobile(this.FormMSG(58, 'Success'), this.FormMSG(61, 'Sub category created successfully'));
			}
			this.$emit('budget-categorie-modal:success');
			this.emitEventClose();
		},
		async getCategories() {
			this.mapCategories = [];
			await getBudgetHierarchicalCategoryJson(-2, false, null, -1, false, this.action === 'add-parent').then((result) => {
				this.mapCategories = removeAttributeTree(result, 'children', null);
			});
		},
		emitEventClose() {
			this.resetData();
			this.$emit('budget-categorie-modal:close');
		},
		resetData() {
			this.dataToEdit = {
				co2Category: 0,
				visibleInApp: true,
				costCenter: '',
				categoryName: '',
				budgetAmount: 0,
				efc: 0,
				initialSpent: 0,
				parentCategory: null,
				departmentNumber: 0,
				contentType: 0
			};
		}
	}
};
</script>

<style scoped></style>
