<template>
	<div>
		<b-row>
			<b-col>
				<filter-budget-advanced
					show-import
					hide-person-section
					hide-expense-details-section
					hide-supplier-section
					:show-import="false"
					:label-expense-details="FormMSG(148, 'Expense details')"
					:filter-type="0"
					@filter-budget-advanced:click-selection="handleFilterBudgetAdvancedClickSelection"
				/>
			</b-col>
		</b-row>

		<total-general-budget id-collapse="CollapseForPerCategory" :budget-total="BudgetTotalData" />
		<b-row class="mt-3">
			<b-col>
				<table-hierarchical-budget
					:budget-parents="budgetParents"
					:filters="CatsFilter"
					:department-id="filter.departmentId"
					:start-date="filter.startDate"
					:end-date="filter.endDate"
					@table-hierarchical-budget:modify-EFC="handleTableHierarchicalBudgetModifyEFC"
					@table-hierarchical-budget:edit="handleTableHierarchicalBudgetEdit"
					@table-hierarchical-budget:add-sub-category="handleTableHierarchicalBudgetAddSubCategory"
				/>
			</b-col>
		</b-row>

		<b-row class="mt-3" v-if="canAddOrEditCode">
			<b-col>
				<b-button variant="primary" @click="handleClickNewCode">
					{{ FormMSG(25, 'New code') }}
				</b-button>
			</b-col>
		</b-row>

		<budget-categorie-modal
			:open="showBudgetCategorieModal"
			:budget="budgetToEdit"
			:action="actionBudgetCat"
			@budget-categorie-modal:close="handleBudgetCategorieModalClose"
			@budget-categorie-modal:success="handleBudgetCategorieModalSuccess"
		/>
		<modifyEFC-modal
			:open="showModifyEFCModal"
			:budget="EFCToEdit"
			@modify-EFC-modal:close="handleModifyEFCModalClose"
			@modify-EFC-modal:success="handleModifyEFCModalSuccess"
		/>
	</div>
</template>

<script>
import languageMessages from '@/mixins/languageMessages';
import { Filter } from 'lucide-vue';
import { store } from '@/store';

import TableHierarchicalBudget from '@/components/Budget/TableHierarchicalBudget';
import FilterBudget from '@/components/Budget/FilterBudget';
import TotalGeneralBudget from '@/components/Budget/TotalGeneralBudget';
import GlobalMixin from '@/mixins/global.mixin';
import FilterBudgetAdvanced from '@/components/Budget/FilterBudgetAdvanced';
import BudgetCategorieModal from '@/components/Modals/BudgetCategorieModal';
import ModifyEFCModal from '@/components/Modals/ModifyEFCModal';

export default {
	name: 'BudgetPerCategory',

	mixins: [languageMessages, GlobalMixin],

	props: {
		budgetHierarchicalData: { type: Array, required: true }
	},

	components: {
		TableHierarchicalBudget,
		Filter,
		FilterBudget,
		TotalGeneralBudget,
		FilterBudgetAdvanced,
		BudgetCategorieModal,
		ModifyEFCModal
	},

	data() {
		return {
			selectedDepartment: 0,
			selectedCategory: 0,
			budgetParents: [],
			CatsFilter: '',
			filter: {
				departmentId: -1,
				startDate: '',
				endDate: '',
				forceCompute: false
			},
			BudgetTotalData: {
				amountTotal: 0,
				expenseTotal: 0,
				contracted: 0,
				spent: 0,
				salaries: 0,
				initial: 0,
				purchaseOrdersTotal: 0,
				amountLeft: 0,
				initialExpenses: 0,
				initialSalaries: 0,
				kgCoTwoExpenses: 0,
				kgCoTwoSalaries: 0,
				kgCoTwoTotal: 0,
				totalVariance: 0,
				budgetDetailInitialBudget: 0
			},
			firstLoad: true,
			showBudgetCategorieModal: false,
			showModifyEFCModal: false,

			EFCToEdit: null,
			budgetToEdit: {
				budgetDetailCo2Category: 0,
				budgetDetailAppChoice: true,
				cost_center: '',
				budgetDetailDescription: '',
				budgetDetailInitialBudget: 0,
				budgetDetailInitial: 0,
				budgetDetailStartOfPeriodValue: 0,
				budgetDetailParentId: null,
				budgetDetailVariance: 0,
				department: 0,
				contentType: 0,
				budgetDetailIsLinkedToFunctionId: 0
			},
			actionBudgetCat: 'add-parent',
			addSubCategory: false,
			addParentCategory: false
		};
	},

	watch: {
		budgetHierarchicalData: {
			async handler(val) {
				this.budgetParents = val;

				await this.updateBudgetTotalForEach();
			},
			immediate: true,
			deep: true
		}
	},

	async mounted() {
		await this.$store.dispatch('expenses/getDepartmentsForSalary');
	},
	computed: {
		canAddOrEditCode() {
			return store.canEditAndAddCode();
		}
	},
	created() {
		this.initFilterTime();
	},

	methods: {
		handleTableHierarchicalBudgetAddSubCategory(payload) {
			this.actionBudgetCat = 'add-sub';
			// this.addSubCategory = true;
			// this.addParentCategory = false
			this.budgetToEdit = payload;
			this.showBudgetCategorieModal = true;
		},
		handleTableHierarchicalBudgetEdit(payload) {
			this.actionBudgetCat = 'edit';
			// this.addSubCategory = false;
			// this.addParentCategory = false
			this.budgetToEdit = payload;
			this.showBudgetCategorieModal = true;
		},
		handleBudgetCategorieModalSuccess() {
			this.$emit('budget-per-category:add-new');
		},
		handleModifyEFCModalSuccess(payload) {
			this.$emit('budget-per-category:change-EFC', payload);
		},
		handleModifyEFCModalClose() {
			this.EFCToEdit = null;
			this.showModifyEFCModal = false;
		},
		handleTableHierarchicalBudgetModifyEFC(payload) {
			this.EFCToEdit = payload;
			this.showModifyEFCModal = true;
		},
		handleBudgetCategorieModalClose() {
			this.showBudgetCategorieModal = false;
		},
		handleClickNewCode() {
			this.budgetToEdit = {
				budgetDetailCo2Category: 0,
				budgetDetailAppChoice: true,
				cost_center: '',
				budgetDetailDescription: '',
				budgetDetailInitialBudget: 0,
				budgetDetailInitial: 0,
				budgetDetailStartOfPeriodValue: 0,
				budgetDetailParentId: null,
				budgetDetailVariance: 0,
				contentType: 0,
				budgetDetailIsLinkedToFunctionId: 0,
				department: null
			};
			this.actionBudgetCat = 'add-parent';
			// this.addSubCategory = false;
			// this.addParentCategory = true;
			this.showBudgetCategorieModal = true;
		},
		handleFilterBudgetAdvancedClickSelection(payload) {
			this.emitLoading(true);
			let fromDate = payload.startDate;
			let toDate = payload.endDate;

			const fullPath = `${process.env.VUE_APP_GQL}/expenseGridFromTo/?From=${fromDate}&To=${toDate}&Email=0`;
			let axiosTmp = require('axios').create();
			let config = {
				headers: {
					Authorization: 'Bearer ' + store.state.token
				}
			};

			axiosTmp
				.get(fullPath, config)
				.then((response) => {
					if (response.status == 200) {
						var urlStr = process.env.VUE_APP_GQL + '/images/' + response.data;

						this.$nextTick(function () {
							window.open(urlStr); // in a new window
							this.createToastForMobile(this.FormMSG(251, 'Success'), this.FormMSG(250, 'Expenses downloaded successfully'), '', 'success');
						});
					}
				})
				.catch((error) => {
					let errorMsg = '';
					if (error.message.includes('Network')) {
						errorMsg = this.FormMSG(104, 'Network error.');
					} else if (error.response) {
						if (error.response.status == 401) {
							errorMsg = this.FormMSG(103, 'Not connected.');
						} else if (error.response.status == 400) {
							errorMsg = this.FormMSG(102, 'Invalid format.');
						} else if (error.response.status == 404) {
							errorMsg = this.FormMSG(101, 'No Data in the selected period.');
						}
					}

					this.createToastForMobile(this.FormMSG(249, 'Success'), errorMsg, '', 'danger');
				})
				.finally(() => this.emitLoading(false));
		},
		initFilterTime() {
			this.filter.startDate = store.state.myProject.startDate;
			this.filter.endDate = store.state.myProject.endDate;
		},
		emitLoading(value) {
			this.$emit('budget-per-category:loading', value);
		},
		initBudgetTotal() {
			this.BudgetTotalData.amountLeft = 0;
			this.BudgetTotalData.initial = 0;
			this.BudgetTotalData.salaries = 0;
			this.BudgetTotalData.contracted = 0;
			this.BudgetTotalData.initialSalaries = 0;
			this.BudgetTotalData.initialExpenses = 0;
			this.BudgetTotalData.kgCoTwoSalaries = 0;
			this.BudgetTotalData.kgCoTwoTotal = 0;
			this.BudgetTotalData.kgCoTwoExpenses = 0;
			this.BudgetTotalData.purchaseOrdersTotal = 0;
			this.BudgetTotalData.expenseTotal = 0;
			this.BudgetTotalData.amountTotal = 0;
			this.BudgetTotalData.spent = 0;
			this.BudgetTotalData.totalVariance = 0;
			this.BudgetTotalData.budgetDetailInitialBudget = 0;
		},
		async updateBudgetTotalForEach() {
			this.initBudgetTotal();
			if (this.budgetParents.length > 0) {
				this.budgetParents.forEach((BudgetData) => {
					this.BudgetTotalData.kgCoTwoTotal += BudgetData.kgCoTwo;

					if (this.BudgetTotalData.salaries == 0) {
						this.BudgetTotalData.kgCoTwoExpenses += BudgetData.kgCoTwo;
					} else {
						this.BudgetTotalData.kgCoTwoSalaries += BudgetData.kgCoTwo;
					}

					this.BudgetTotalData.amountLeft += BudgetData.amountLeft;
					this.BudgetTotalData.initial += BudgetData.budgetDetailInitial;
					this.BudgetTotalData.salaries += BudgetData.salaries;
					this.BudgetTotalData.contracted += BudgetData.contracted;
					this.BudgetTotalData.initialSalaries += BudgetData.initialSalaries;
					this.BudgetTotalData.initialExpenses += BudgetData.initialExpenses;
					this.BudgetTotalData.purchaseOrdersTotal += BudgetData.purchaseOrdersTotal;
					this.BudgetTotalData.expenseTotal += BudgetData.expenseTotal;
					this.BudgetTotalData.amountTotal += BudgetData.amountTotal;
					this.BudgetTotalData.spent = this.BudgetTotalData.salaries + this.BudgetTotalData.expenseTotal + this.BudgetTotalData.purchaseOrdersTotal;
					this.BudgetTotalData.totalVariance += BudgetData.budgetDetailVariance;
					this.BudgetTotalData.budgetDetailInitialBudget += BudgetData.budgetDetailInitialBudget;

					if (BudgetData.amountLeft < 0) {
						BudgetData._cellVariants = {
							amountLeft: 'danger'
						};
					} else {
						BudgetData._cellVariants = {
							amountLeft: 'success'
						};
					}
				});
			}
		}
	}
};
</script>
