var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            [
              _c("filter-budget-advanced", {
                attrs: {
                  "show-import": "",
                  "hide-person-section": "",
                  "hide-expense-details-section": "",
                  "hide-supplier-section": "",
                  "show-import": false,
                  "label-expense-details": _vm.FormMSG(148, "Expense details"),
                  "filter-type": 0,
                },
                on: {
                  "filter-budget-advanced:click-selection":
                    _vm.handleFilterBudgetAdvancedClickSelection,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("total-general-budget", {
        attrs: {
          "id-collapse": "CollapseForPerCategory",
          "budget-total": _vm.BudgetTotalData,
        },
      }),
      _c(
        "b-row",
        { staticClass: "mt-3" },
        [
          _c(
            "b-col",
            [
              _c("table-hierarchical-budget", {
                attrs: {
                  "budget-parents": _vm.budgetParents,
                  filters: _vm.CatsFilter,
                  "department-id": _vm.filter.departmentId,
                  "start-date": _vm.filter.startDate,
                  "end-date": _vm.filter.endDate,
                },
                on: {
                  "table-hierarchical-budget:modify-EFC":
                    _vm.handleTableHierarchicalBudgetModifyEFC,
                  "table-hierarchical-budget:edit":
                    _vm.handleTableHierarchicalBudgetEdit,
                  "table-hierarchical-budget:add-sub-category":
                    _vm.handleTableHierarchicalBudgetAddSubCategory,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm.canAddOrEditCode
        ? _c(
            "b-row",
            { staticClass: "mt-3" },
            [
              _c(
                "b-col",
                [
                  _c(
                    "b-button",
                    {
                      attrs: { variant: "primary" },
                      on: { click: _vm.handleClickNewCode },
                    },
                    [
                      _vm._v(
                        "\n\t\t\t\t" +
                          _vm._s(_vm.FormMSG(25, "New code")) +
                          "\n\t\t\t"
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c("budget-categorie-modal", {
        attrs: {
          open: _vm.showBudgetCategorieModal,
          budget: _vm.budgetToEdit,
          action: _vm.actionBudgetCat,
        },
        on: {
          "budget-categorie-modal:close": _vm.handleBudgetCategorieModalClose,
          "budget-categorie-modal:success":
            _vm.handleBudgetCategorieModalSuccess,
        },
      }),
      _c("modifyEFC-modal", {
        attrs: { open: _vm.showModifyEFCModal, budget: _vm.EFCToEdit },
        on: {
          "modify-EFC-modal:close": _vm.handleModifyEFCModalClose,
          "modify-EFC-modal:success": _vm.handleModifyEFCModalSuccess,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }