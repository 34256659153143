<template>
	<div class="container-layout form px-0">
		<filter-budget-advanced
			:show-user-selector="false"
			:label-expense-details="FormMSG(148, 'Expense details')"
			use-department-for-expense
			:hide-process-filter="false"
			:filter-type="2"
			@filter-budget-advanced:click-selection="handleFilterBudgetAdvancedClickSelection"
		/>
		<div class="row mt-3 hide-on-tablet">
			<b-col md="5">
				<b-form-group class="mb-0">
					<b-input-group v-if="$screen.width >= 992">
						<filterInputSearch id="expense-search-id" @filtered-users="activeFilter" @handle-init-filter="onHandleInitFilter" />
						<!-- <b-form-input v-model="filter" type="text" id="filterInput" :placeholder="this.FormMSG(37, 'Type to Search')" />
						<b-input-group-append class="cursor-pointer">
							<b-input-group-text class="btn-search">
								<Search color="#FFFFFF" :size="16" class="icon" :stroke-width="2.5" v-if="filter.length === 0" />
								<X color="#FFFFFF" :size="16" class="icon" :stroke-width="2.5" @click="filter = ''" v-else />
							</b-input-group-text>
						</b-input-group-append> -->
					</b-input-group>
				</b-form-group>
			</b-col>
			<b-col md="7">
				<custom-pagination
					v-if="!haveActiveFilter"
					:total-records="totalRecords"
					:initial-perpage="perPage"
					:initial-offset="offset"
					:initial-limit="limit"
					:filter-type="2"
					@custom-pagination:previous="handleClickLoadMore"
					@custom-pagination:forward="handleClickLoadMore"
					@custom-pagination:change-per-page="handleClickLoadMore"
				/>
			</b-col>
		</div>
		<div class="row mt-3 ml-1">
			<CardListBuilder class="hide-on-desktop" v-if="!$screen.md" :items="expenses" :filter="filter" style="width: 100%" :fields="bgFieldsMobile">
				<template slot="actions" slot-scope="data">
					<div class="mt-2">
						<div @click="openEditExpense(data.item)" class="edit" role="button">
							<edit />
						</div>
						&nbsp;
						<div v-if="data.item.images.length > 0" @click="showPicture(data.item.images)" class="pic" role="button">
							<photo />
						</div>
					</div>
				</template>
			</CardListBuilder>
			<b-table
				responsive="sm"
				:filter="filter"
				v-else
				:items="expenses"
				:fields="bgFields"
				style="width: 100%; font-weight: 500"
				sticky-header="700px"
				:head-variant="hv"
				:hover="true"
				bordered
				small
				show-empty
				selectable
				:selectedVariant="selectedColor"
				:select-mode="selectMode"
				:empty-text="FormMSG(265, 'No budget expenses found')"
			>
				<template #cell(processed)="{ item, index }">
					<div>
						<b-form-checkbox
							v-model="item.processed"
							size="lg"
							:value="true"
							:unchecked-value="false"
							@change="handleChangeProcess($event, item, index)"
						/>
					</div>
				</template>
				<template #cell(validated)="data">
					<div class="wrap-status">
						<div :class="`status ${data.item.statusClass}`" style="font-size: 0.7rem">
							{{ data.item.validatedStatus }}
						</div>
					</div>
				</template>
				<template #cell(userDepartmentName)="data">
					<div class="text-left">
						<div>
							{{ data.item.userDepartmentName.charAt(0).toUpperCase() + data.item.userDepartmentName.slice(1).toLowerCase() }} -
							{{ data.item.functionName.charAt(0).toUpperCase() + data.item.functionName.slice(1).toLowerCase() }}
						</div>
					</div>
				</template>
				<template #cell(option)="data">
					<b-dropdown no-caret dropleft v-if="data.item.images.length > 0 || canEditExpense" offset="25" variant="none" size="sm">
						<template #button-content>
							<more-vertical :size="16" />
						</template>
						<b-dropdown-item v-if="canEditExpense" @click="openEditExpense(data.item)">
							<edit :size="16" /> {{ FormMSG(1000, 'Edit') }}
						</b-dropdown-item>
						<b-dropdown-item v-if="data.item.images.length > 0" @click="showPicture(data.item.images)">
							<photo :size="16" /> {{ FormMSG(1001, ' See picture') }}
						</b-dropdown-item>
					</b-dropdown>
				</template>
			</b-table>
		</div>
		<ExpenseService
			v-model="isNewExpenseModalOpen"
			:expense-type="newExpenseType"
			:edit-data="currEditExpense"
			:user-id="currEditUserId"
			@expense-service-main:error="getError"
			@submited="handleModalSubmited"
			@expense-form-component:close="onCloseExpenseForm"
		/>
		<script-export-modal
			v-model="isScriptExpenseReportOpen"
			:items="scripts"
			:custom="customReport"
			export-type="expenseitem"
			@script-export-modal:loading="handleLoading"
			@script-export-modal:closed="expenseRerpotOnClosed"
		/>
	</div>
</template>

<script>
import languageMessages from '@/mixins/languageMessages';
import { rendCurrency, rendKgCo2 } from '~helpers';
import GlobalMixin from '@/mixins/global.mixin';
import { MoreVertical, Edit, Image as Photo, Search, X, AlertTriangle } from 'lucide-vue';
import moment from 'moment';
import { getTextFromMenuNumberAndMenuValue } from '@/cruds/language.crud';
import ExpenseService from '@/components/ExpenseService/Main';
import { getProjectUserExpenseDetail } from '@/cruds/budget.crud';
import FilterBudgetAdvanced from '@/components/Budget/FilterBudgetAdvanced';
import { store } from '@/store';
import Treeselect from '@riophae/vue-treeselect';
import CustomPagination from '@/components/Budget/CustomPagination';
import { getFileExtension } from '@/shared/helpers';
import { isNil, formatFileName } from '~utils';
import _ from 'lodash';
import { expenseItemReport } from '@/cruds/export.crud';
import filterInputSearch from '../users/filterInput.vue';
import { getScripts } from '@/cruds/script.crud';
import ScriptExportModal from '@/components/Export/ScriptExportModal';
import { FILENAME_REPORT, OUTPUT_FORMAT_EXPORT } from '@/shared/constants';
import { changeStatusOfProcess } from '@/cruds/budget.crud';

const validatedText = async (validated) => {
	let value = null;
	await new Promise((resolve, reject) => {
		resolve(getTextFromMenuNumberAndMenuValue(1008, validated));
		reject(false);
	}).then((data) => {
		value = data;
	});

	return value;
};
const validatedColor = (validated) => {
	let retval = 'not-submitted';
	if (validated == 1) {
		retval = 'info';
	} else if (validated == 2) {
		retval = 'pending';
	} else if (validated == 8) {
		retval = 'validated';
	} else if (validated == 4 || validated == 16) {
		retval = 'refused';
	}
	return retval;
};

export default {
	name: 'BudgetExpenses',
	components: {
		MoreVertical,
		Photo,
		Edit,
		ExpenseService,
		FilterBudgetAdvanced,
		Search,
		X,
		Treeselect,
		AlertTriangle,
		CustomPagination,
		ScriptExportModal,
		filterInputSearch
	},
	mixins: [languageMessages, GlobalMixin],
	props: {
		initDataForFilterAdvanced: { type: Object, required: true },
		budgetExpensesData: { type: Array, required: true },
		totalRecords: { type: Number, required: 0 }
	},
	data() {
		return {
			visible: false,
			caption1: '',
			hv: 'dark',
			selectMode: 'single',
			selectedColor: 'primary',
			expenses: [],
			filter: '',
			openShowRoomPicModal: false,
			haveActiveFilter: false,
			isNewExpenseModalOpen: false,
			newExpenseType: 0,
			currEditExpense: null,
			currEditUserId: null,
			filtersSelected: {},
			firstLoad: true,
			perPage: 100,
			offset: 0,
			limit: 100,
			cookiesPaginationBudgetExpenses: 'pagination_budget_expenses',
			isScriptExpenseReportOpen: false,
			scripts: [],
			customReport: null,
			scriptType: 14,
			removeFromReportKeys: []
		};
	},
	watch: {
		budgetExpensesData: {
			async handler(val) {
				let arr = [];
				const data = val;
				for (const item of data) {
					let exp = item.expenseItem;
					exp.fullName = exp.user.name + ' ' + exp.user.firstName;
					exp.statusClass = validatedColor(exp.validated);
					exp.validatedStatus = await validatedText(exp.validated);
					arr.push(exp);
				}
				this.expenses = arr;
			},
			immediate: true,
			deep: true
		}
	},
	computed: {
		canEditExpense() {
			return store.canEditExpense();
		},
		bgFields() {
			return [
				{
					key: 'processed',
					label: this.FormMSG(521, 'Processed'),
					sortable: false,
					class: 'text-center w-90-px'
				},
				{
					key: 'date',
					label: this.FormMSG(17, 'Date'),
					formatter: (value) => {
						return moment(value).format('DD/MM/YYYY');
					},
					sortable: true,
					class: 'text-left'
				},
				{
					key: 'type',
					label: this.FormMSG(124, 'Type'),
					formatter: (v) => {
						return this.getLabelTypeExpense(v);
					},
					sortable: true,
					class: 'text-center'
				},
				{
					key: 'description',
					label: this.FormMSG(200, 'Descritpion'),
					sortable: true,
					class: 'text-left'
				},
				{
					key: 'categoryName',
					label: this.FormMSG(211, 'Category'),
					formatter: (v, z, root) => {
						return root.costCenter + ' - ' + root.categoryName.toUpperCase();
					},
					sortable: true,
					class: 'text-left'
				},
				{
					key: 'supplierName',
					label: this.FormMSG(221, 'Supplier'),
					formatter: (v) => v.charAt(0).toUpperCase() + v.slice(1).toLowerCase(),
					sortable: true,
					class: 'text-left'
				},
				{
					key: 'fullName',
					label: this.FormMSG(231, 'Full Name'),
					formatter: (v) => v.charAt(0).toUpperCase() + v.slice(1).toLowerCase(),
					sortable: true,
					class: 'text-left'
				},
				{
					key: 'userDepartmentName',
					label: this.FormMSG(241, 'Department & Function'),
					sortable: true,
					class: 'text-left'
				},
				{
					key: 'validated',
					label: this.FormMSG(24, 'Status'),
					sortable: true,
					class: 'text-center'
				},
				{
					key: 'amountTotal',
					label: this.FormMSG(25, 'Amount'),
					formatter: (value) => {
						return this.displayCurrency(value);
					},
					sortable: true,
					class: 'text-right'
				},
				{
					key: 'kgCoTwo',
					label: 'CO2',
					formatter: (value) => {
						return this.displayKgCo2(value);
					},
					class: 'text-right',
					sortable: true
				},
				{
					key: 'option',
					label: this.FormMSG(126, 'Option'),
					class: 'text-center'
				}
			];
		},
		bgFieldsMobile() {
			return [
				{
					key: 'expenseDate',
					label: this.FormMSG(17, 'Date'),
					formatter: (value) => {
						return moment(value).format('DD/MM/YYYY');
					},
					sortable: true,
					class: 'text-left'
				},
				{
					key: 'description',
					label: this.FormMSG(200, 'Descritpion'),
					sortable: true,
					class: 'text-left'
				},
				{
					key: 'categoryName',
					label: this.FormMSG(211, 'Category'),
					formatter: (v, z, root) => {
						return root.costCenter + ' - ' + root.categoryName.toUpperCase();
					},
					sortable: true,
					class: 'text-left'
				},
				{
					key: 'supplier',
					label: this.FormMSG(221, 'Supplier'),
					formatter: (v) => v.charAt(0).toUpperCase() + v.slice(1).toLowerCase(),
					sortable: true,
					class: 'text-left'
				},
				{
					key: 'fullName',
					label: this.FormMSG(231, 'Full Name'),
					formatter: (v) => v.charAt(0).toUpperCase() + v.slice(1).toLowerCase(),
					sortable: true,
					class: 'text-left'
				},
				{
					key: 'department',
					label: this.FormMSG(241, 'Department & Function'),
					sortable: true,
					class: 'text-left'
				},
				{
					key: 'validatedStatus',
					label: this.FormMSG(24, 'Status'),
					sortable: true,
					class: 'text-left'
				},
				{
					key: 'amount',
					label: this.FormMSG(25, 'Amount'),
					formatter: (value) => {
						return this.displayCurrency(value);
					},
					sortable: true,
					class: 'text-left'
				},
				{
					key: 'co2',
					label: 'CO2',
					formatter: (value) => {
						return this.displayKgCo2(value);
					},
					class: 'text-left',
					sortable: true
				}
			];
		}
	},
	created() {
		this.caption1 = this.FormMSG(1, 'Show filters');
	},
	methods: {
		activeFilter(keyword) {
			this.haveActiveFilter = true;
			this.$emit('activate-filter-by-keyword', keyword);
		},
		onHandleInitFilter() {
			this.filter = '';
			this.haveActiveFilter = false;
			this.$emit('activate-filter-by-keyword', null);
		},
		async handleChangeProcess(payload, item, index) {
			await changeStatusOfProcess(+item.id, 2, payload);
		},
		/**
		 * @param {Array} images
		 */
		showPicture(images) {
			if (isNil(images)) return;

			const list = images.map((img) => ({
				xid: img,
				src: `${process.env.VUE_APP_GQL}/images/${img}`,
				thumb: process.env.VUE_APP_PATH_IMG_THUMB_URL + img,
				ext: getFileExtension(img)
			}));

			this.$previewImages({
				images: list,
				focusIndex: 0,
				options: {
					hideLikeDislike: true,
					hideCommentButton: true,
					hideDisLikeButton: true
				}
			});
		},
		handleClickLoadMore({ offset, limit, perPage }) {
			this.offset = offset;
			this.limit = limit;
			this.perPage = perPage;

			this.$emit('budget-expenses:load-more', {
				offset,
				limit,
				perPage: perPage
			});
		},
		getLabelTypeExpense(value) {
			let name = '';
			const labelTypes = [
				{ value: 0, text: this.FormMSG(327, 'Ticket') },
				{ value: 1, text: this.FormMSG(328, 'Invoice') },
				{ value: 2, text: this.FormMSG(329, 'Travel') }
			];
			const types = labelTypes.filter((lT) => lT.value === value);
			if (types.length > 0) {
				for (const typeName of types) {
					return (name = typeName.text.toUpperCase());
				}
			}
			return name;
		},
		async handleFilterBudgetAdvancedClickSelection(oPayload) {
			this.emitLoading(true);
			const payload = _.omit(oPayload, this.removeFromReportKeys);

			this.scripts = await getScripts(store.state.myProject.licenseID, store.state.myProject.id, this.scriptType, true);

			let script = { id: 0, fileName: null, ext: 'xlsx' };

			if (this.scripts.length > 1) {
				this.customReport = payload;
				this.isScriptExpenseReportOpen = true;
				this.emitLoading(false);
			} else {
				this.customReport = null;

				if (this.scripts.length === 1) {
					script = {
						id: this.scripts[0].id,
						fileName: this.scripts[0].customExportFileName,
						ext: this.getExtensionScript(
							OUTPUT_FORMAT_EXPORT.at(this.scripts[0].outputFileFormat),
							this.scripts[0].customExtension,
							getFileExtension(this.scripts[0].fileName)
						)
					};
				}

				let fileName = this.getFileNameScript(FILENAME_REPORT.REPORT_EXPENSE_ITEM, script.id, script.fileName);

				await expenseItemReport(payload, script.id)
					.then((result) => {
						this.forceDownload(result, fileName + '.' + script.ext);
					})
					.catch((e) => {
						console.error(e);
						this.emitLoading(false);
					})
					.finally(() => {
						this.emitLoading(false);
					});
			}
		},
		async handleModalSubmited() {
			this.createToastForMobile(this.FormMSG(1620, 'Success'), this.FormMSG(400, 'Your expense was saved successfully.'), '', 'success');

			this.currEditUserId = null;
			this.currEditExpense = null;
			this.isNewExpenseModalOpen = false;
			await this.handleFilterBudgetAdvancedChange(this.filtersSelected);
		},
		onCloseExpenseForm() {
			this.currEditExpense = null;
			this.currEditUserId = null;
			this.isNewExpenseModalOpen = false;
		},
		displayCurrency(value) {
			return rendCurrency(value);
		},
		displayKgCo2(value) {
			return rendKgCo2(value);
		},
		async handleFilterBudgetAdvancedChange(payload) {
			this.emitLoading(true);
			this.expenses = [];
			const result = await getProjectUserExpenseDetail(payload);

			let arr = [];
			const data = result;
			for (const item of data) {
				let exp = item.expenseItem;

				item.fullName = '';
				exp.fullName = exp.user.name + ' ' + exp.user.firstName;
				exp.statusClass = validatedColor(exp.validated);
				exp.validatedStatus = await validatedText(exp.validated);
				arr.push(exp);
			}
			this.expenses = arr;
			this.emitLoading(false);
		},
		emitLoading(value) {
			this.$emit('budget-expenses:loading', value);
		},
		openEditExpense(expense) {
			if (expense.type === 2) {
				expense.originalKm = expense.km;
				expense.originalFromLocation = expense.fromLocation;
				expense.originalToLocation = expense.toLocation;
			} else {
				expense.originalImages = expense.images;
			}
			this.newExpenseType = expense.type;
			this.currEditExpense = expense;
			this.currEditUserId = parseInt(expense.user.id, 10);
			this.isNewExpenseModalOpen = true;
		},
		expenseRerpotOnClosed() {
			this.isScriptExpenseReportOpen = false;
			this.emitLoading(false);
		},
		handleLoading(payload) {
			if (payload) {
				this.emitLoading(false);
			}
		},
		getError(payload) {
			if (!_.isNil(payload) && !_.isNil(payload.status) && payload.status === 509) {
				this.isNewExpenseModalOpen = true;
			}
		}
	}
};
</script>
