var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container-layout form px-0" },
    [
      _c("filter-budget-advanced", {
        attrs: {
          "show-user-selector": false,
          "use-department-for-expense": "",
          "filter-type": 3,
          "label-expense-details": _vm.FormMSG(148, "Expense details"),
          "hide-process-filter": false,
          "hide-invoiced-to-production": false,
        },
        on: {
          "filter-budget-advanced:click-selection":
            _vm.handleFilterBudgetAdvancedClickSelection,
        },
      }),
      _c(
        "div",
        { staticClass: "row mt-3 hide-on-tablet" },
        [
          _c(
            "b-col",
            { attrs: { md: "5" } },
            [
              _c(
                "b-form-group",
                { staticClass: "mb-0" },
                [
                  _vm.$screen.width >= 992
                    ? _c(
                        "b-input-group",
                        [
                          _c("filterInputSearch", {
                            attrs: { id: "po-search-id" },
                            on: {
                              "filtered-users": _vm.activeFilter,
                              "handle-init-filter": _vm.onHandleInitFilter,
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { md: "7" } },
            [
              !_vm.haveActiveFilter
                ? _c("custom-pagination", {
                    attrs: {
                      "total-records": _vm.totalRecords,
                      "initial-perpage": _vm.perPage,
                      "initial-offset": _vm.offset,
                      "initial-limit": _vm.limit,
                      "filter-type": 3,
                    },
                    on: {
                      "custom-pagination:previous": _vm.handleClickLoadMore,
                      "custom-pagination:forward": _vm.handleClickLoadMore,
                      "custom-pagination:change-per-page":
                        _vm.handleClickLoadMore,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "row mt-3 ml-1" },
        [
          !_vm.$screen.md
            ? _c("CardListBuilder", {
                staticClass: "hide-on-desktop",
                staticStyle: { width: "100%" },
                attrs: {
                  items: _vm.expenses,
                  filter: _vm.filter,
                  fields: _vm.bgFieldsMobile,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "actions",
                      fn: function (data) {
                        return [
                          _c(
                            "div",
                            {
                              attrs: { role: "button" },
                              on: {
                                click: function ($event) {
                                  return _vm.goToDetail(data.item)
                                },
                              },
                            },
                            [_c("eye", { attrs: { size: 16 } })],
                            1
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  117439835
                ),
              })
            : _c("b-table", {
                staticStyle: { width: "100%", "font-weight": "500" },
                attrs: {
                  responsive: "sm",
                  filter: _vm.filter,
                  items: _vm.expenses,
                  fields: _vm.bgFields,
                  "sticky-header": "700px",
                  "head-variant": _vm.hv,
                  hover: true,
                  bordered: "",
                  small: "",
                  "show-empty": "",
                  selectable: "",
                  selectedVariant: _vm.selectedColor,
                  "select-mode": _vm.selectMode,
                  "empty-text": _vm.FormMSG(289, "No purchase orders found"),
                },
                scopedSlots: _vm._u([
                  {
                    key: "cell(processed)",
                    fn: function ({ item, index }) {
                      return [
                        _c(
                          "div",
                          [
                            _c("b-form-checkbox", {
                              attrs: {
                                size: "lg",
                                value: true,
                                "unchecked-value": false,
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.handleChangeProcess(
                                    $event,
                                    item,
                                    index
                                  )
                                },
                              },
                              model: {
                                value: item.processed,
                                callback: function ($$v) {
                                  _vm.$set(item, "processed", $$v)
                                },
                                expression: "item.processed",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                  {
                    key: "cell(picture)",
                    fn: function (data) {
                      return [
                        data.item.images && data.item.images.length > 0
                          ? _c(
                              "button",
                              {
                                staticClass: "btn-transparent text-success",
                                on: {
                                  click: function ($event) {
                                    return _vm.showPicture(data.item.images)
                                  },
                                },
                              },
                              [
                                _c(_vm.getLucideIcon("Image"), {
                                  tag: "component",
                                  attrs: { size: 20 },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                  {
                    key: "cell(validated)",
                    fn: function (data) {
                      return [
                        _c("div", { staticClass: "wrap-status" }, [
                          _c(
                            "div",
                            {
                              class: `status ${data.item.statusClass}`,
                              staticStyle: { "font-size": "0.7rem" },
                            },
                            [
                              _vm._v(
                                "\n\t\t\t\t\t\t" +
                                  _vm._s(data.item.validatedStatus) +
                                  "\n\t\t\t\t\t"
                              ),
                            ]
                          ),
                        ]),
                      ]
                    },
                  },
                  {
                    key: "cell(departmentName)",
                    fn: function (data) {
                      return [
                        _c("div", { staticClass: "text-left" }, [
                          _c("div", [
                            _vm._v(
                              "\n\t\t\t\t\t\t" +
                                _vm._s(
                                  data.item.departmentName
                                    .charAt(0)
                                    .toUpperCase() +
                                    data.item.departmentName
                                      .slice(1)
                                      .toLowerCase()
                                ) +
                                " -\n\t\t\t\t\t\t" +
                                _vm._s(
                                  data.item.functionName
                                    .charAt(0)
                                    .toUpperCase() +
                                    data.item.functionName
                                      .slice(1)
                                      .toLowerCase()
                                ) +
                                "\n\t\t\t\t\t"
                            ),
                          ]),
                        ]),
                      ]
                    },
                  },
                  {
                    key: "cell(option)",
                    fn: function (data) {
                      return [
                        _c(
                          "div",
                          {
                            attrs: { role: "button" },
                            on: {
                              click: function ($event) {
                                return _vm.goToDetail(data.item)
                              },
                            },
                          },
                          [_c("eye", { attrs: { size: 16 } })],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
        ],
        1
      ),
      _c("script-export-modal", {
        attrs: {
          items: _vm.scripts,
          custom: _vm.customReport,
          "export-type": "po",
        },
        on: {
          "script-export-modal:loading": _vm.handleLoading,
          "script-export-modal:closed": _vm.poRerpotOnClosed,
        },
        model: {
          value: _vm.isScriptPoReportOpen,
          callback: function ($$v) {
            _vm.isScriptPoReportOpen = $$v
          },
          expression: "isScriptPoReportOpen",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }