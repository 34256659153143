<style>
.toggler:after {
	display: inline-block;
	margin-left: 0.255em;
	vertical-align: 0.255em;
	content: '';
	border-top: 0.3em solid;
	border-right: 0.3em solid transparent;
	border-bottom: 0;
	border-left: 0.3em solid transparent;
}

.blueText {
	color: #225cbd;
	font-size: 10px;
}
</style>

<template>
	<div class="container-layout form px-0">
		<filter-budget-advanced
			:show-user-selector="false"
			use-department-for-expense
			:filter-type="3"
			:label-expense-details="FormMSG(148, 'Expense details')"
			:hide-process-filter="false"
			:hide-invoiced-to-production="false"
			@filter-budget-advanced:click-selection="handleFilterBudgetAdvancedClickSelection"
		/>
		<div class="row mt-3 hide-on-tablet">
			<b-col md="5">
				<b-form-group class="mb-0">
					<b-input-group v-if="$screen.width >= 992">
						<filterInputSearch id="po-search-id" @filtered-users="activeFilter" @handle-init-filter="onHandleInitFilter" />
						<!-- <b-form-input v-model="filter" type="text" id="filterInput" :placeholder="this.FormMSG(37, 'Type to Search')" />
						<b-input-group-append class="cursor-pointer">
							<b-input-group-text class="btn-search">
								<Search color="#FFFFFF" :size="16" class="icon" :stroke-width="2.5" v-if="filter.length === 0" />
								<X color="#FFFFFF" :size="16" class="icon" :stroke-width="2.5" @click="filter = ''" v-else />
							</b-input-group-text>
						</b-input-group-append> -->
					</b-input-group>
				</b-form-group>
			</b-col>
			<b-col md="7">
				<custom-pagination
					v-if="!haveActiveFilter"
					:total-records="totalRecords"
					:initial-perpage="perPage"
					:initial-offset="offset"
					:initial-limit="limit"
					:filter-type="3"
					@custom-pagination:previous="handleClickLoadMore"
					@custom-pagination:forward="handleClickLoadMore"
					@custom-pagination:change-per-page="handleClickLoadMore"
				/>
			</b-col>
		</div>
		<div class="row mt-3 ml-1">
			<CardListBuilder class="hide-on-desktop" v-if="!$screen.md" :items="expenses" :filter="filter" style="width: 100%" :fields="bgFieldsMobile">
				<template slot="actions" slot-scope="data">
					<div @click="goToDetail(data.item)" role="button">
						<eye :size="16" />
					</div>
				</template>
			</CardListBuilder>
			<b-table
				v-else
				responsive="sm"
				:filter="filter"
				:items="expenses"
				:fields="bgFields"
				style="width: 100%; font-weight: 500"
				sticky-header="700px"
				:head-variant="hv"
				:hover="true"
				bordered
				small
				show-empty
				selectable
				:selectedVariant="selectedColor"
				:select-mode="selectMode"
				:empty-text="FormMSG(289, 'No purchase orders found')"
			>
				<template #cell(processed)="{ item, index }">
					<div>
						<b-form-checkbox
							v-model="item.processed"
							size="lg"
							:value="true"
							:unchecked-value="false"
							@change="handleChangeProcess($event, item, index)"
						/>
					</div>
				</template>
				<template #cell(picture)="data">
					<button v-if="data.item.images && data.item.images.length > 0" class="btn-transparent text-success" @click="showPicture(data.item.images)">
						<component :is="getLucideIcon('Image')" :size="20" />
					</button>
				</template>
				<template #cell(validated)="data">
					<div class="wrap-status">
						<div :class="`status ${data.item.statusClass}`" style="font-size: 0.7rem">
							{{ data.item.validatedStatus }}
						</div>
					</div>
				</template>
				<template #cell(departmentName)="data">
					<div class="text-left">
						<div>
							{{ data.item.departmentName.charAt(0).toUpperCase() + data.item.departmentName.slice(1).toLowerCase() }} -
							{{ data.item.functionName.charAt(0).toUpperCase() + data.item.functionName.slice(1).toLowerCase() }}
						</div>
					</div>
				</template>
				<template #cell(option)="data">
					<div @click="goToDetail(data.item)" role="button">
						<eye :size="16" />
					</div>
				</template>
			</b-table>
		</div>
		<script-export-modal
			v-model="isScriptPoReportOpen"
			:items="scripts"
			:custom="customReport"
			export-type="po"
			@script-export-modal:loading="handleLoading"
			@script-export-modal:closed="poRerpotOnClosed"
		/>
	</div>
</template>

<script>
import languageMessages from '@/mixins/languageMessages';
import { store } from '@/store';
import { rendCurrency, rendKgCo2 } from '~helpers';
import GlobalMixin from '@/mixins/global.mixin';
import { MoreVertical, Edit, Image as Photo, Eye, Search, X } from 'lucide-vue';
import moment from 'moment';
import { getTextFromMenuNumberAndMenuValue } from '@/cruds/language.crud';
import FilterBudgetAdvanced from '@/components/Budget/FilterBudgetAdvanced';
import { getProjectUserPoDetail } from '@/cruds/budget.crud';
import CustomPagination from '@/components/Budget/CustomPagination';
import ScriptExportModal from '@/components/Export/ScriptExportModal';
import _ from 'lodash';
import filterInputSearch from '../users/filterInput.vue';
import { getScripts } from '@/cruds/script.crud';
import { purchaseOrderReport } from '@/cruds/export.crud';
import { getFileExtension } from '@/shared/helpers';
import { isNil, formatFileName } from '~utils';
import { FILENAME_REPORT, OUTPUT_FORMAT_EXPORT } from '@/shared/constants';
import { changeStatusOfProcess } from '@/cruds/budget.crud';

const validatedText = async (validated) => {
	let value = null;
	await new Promise((resolve, reject) => {
		resolve(getTextFromMenuNumberAndMenuValue(1008, validated));
		reject(false);
	}).then((data) => {
		value = data;
	});

	return value;
};

const validatedColor = (validated) => {
	let retval = 'not-submitted';
	if (validated == 1) {
		retval = 'info';
	} else if (validated == 2) {
		retval = 'pending';
	} else if (validated == 8) {
		retval = 'validated';
	} else if (validated == 4 || validated == 16) {
		retval = 'refused';
	}
	return retval;
};

export default {
	name: 'PurchaseOrdersBudget',
	components: {
		MoreVertical,
		Photo,
		Edit,
		Eye,
		FilterBudgetAdvanced,
		Search,
		X,
		CustomPagination,
		ScriptExportModal,
		filterInputSearch
	},
	props: {
		initDataForFilterAdvanced: { type: Object, required: true },
		budgetPOData: { type: Array, required: true },
		totalRecords: { type: Number, required: true }
	},
	mixins: [languageMessages, GlobalMixin],
	data() {
		return {
			visible: false,
			caption1: '',
			hv: 'dark',
			selectMode: 'single',
			selectedColor: 'primary',
			expenses: [],
			filter: '',
			isPoServiceModalOpen: false,
			currentPOSelected: null,
			filtersSelected: {},
			haveActiveFilter: false,
			firstLoad: true,
			perPage: 100,
			offset: 0,
			limit: 100,
			cookiesPaginationBudgetPO: 'pagination_budget_po',
			scriptType: 9,
			isScriptPoReportOpen: false,
			scripts: [],
			customReport: null,
			removeFromReportKeys: []
		};
	},
	watch: {
		budgetPOData: {
			async handler(val) {
				let arr = [];
				const data = val;
				for (const item of data) {
					let PO = item.purchaseOrder;
					PO.fullName = PO.user.name + ' ' + PO.user.firstName;
					PO.statusClass = validatedColor(PO.validated);
					PO.validatedStatus = await validatedText(PO.validated);
					arr.push(PO);
				}
				this.expenses = arr;
			},
			immediate: true,
			deep: true
		}
	},
	computed: {
		bgFields() {
			return [
				{
					key: 'processed',
					label: this.FormMSG(532, 'Processed'),
					sortable: false,
					class: 'text-center'
				},
				{
					key: 'requestDate',
					label: this.FormMSG(17, 'Date'),
					formatter: (value) => {
						return moment(value).format('DD/MM/YYYY');
					},
					sortable: true,
					class: 'text-left'
				},
				{
					key: 'id',
					label: this.FormMSG(200, 'ID'),
					formatter: (value) => {
						return '#' + value;
					},
					sortable: true,
					class: 'text-left'
				},
				{
					key: 'poReference',
					label: this.FormMSG(469, 'PO Reference'),
					sortable: true,
					class: 'text-left'
				},
				{
					key: 'description',
					label: this.FormMSG(211, 'Description'),
					sortable: true,
					class: 'text-left'
				},
				{
					key: 'supplierName',
					label: this.FormMSG(221, 'Supplier'),
					formatter: (v) => v.charAt(0).toUpperCase() + v.slice(1).toLowerCase(),
					sortable: true,
					class: 'text-left'
				},
				{
					key: 'fullName',
					label: this.FormMSG(231, 'Full Name'),
					formatter: (v) => v.charAt(0).toUpperCase() + v.slice(1).toLowerCase(),
					sortable: true,
					class: 'text-left'
				},
				{
					key: 'departmentName',
					label: this.FormMSG(241, 'Department & Function'),
					sortable: true,
					class: 'text-left'
				},
				{
					key: 'validated',
					label: this.FormMSG(24, 'Status'),
					sortable: true,
					class: 'text-center'
				},
				{
					key: 'amountTotal',
					label: this.FormMSG(25, 'Amount'),
					formatter: (value) => {
						return this.displayCurrency(value);
					},
					sortable: true,
					class: 'text-right'
				},
				{
					key: 'picture',
					label: this.FormMSG(458, 'Picture'),
					class: 'text-center'
				},
				{
					key: 'option',
					label: this.FormMSG(120, 'Option'),
					class: 'text-center'
				}
			];
		},
		bgFieldsMobile() {
			return [
				{
					key: 'poDate',
					label: this.FormMSG(17, 'Date'),
					formatter: (value) => {
						return moment(value).format('DD/MM/YYYY');
					},
					sortable: true,
					class: 'text-left'
				},
				{
					key: 'id',
					label: this.FormMSG(200, 'ID'),
					formatter: (value) => {
						return '#' + value;
					},
					sortable: true,
					class: 'text-left'
				},
				{
					key: 'description',
					label: this.FormMSG(211, 'Descritpion'),
					sortable: true,
					class: 'text-left'
				},
				{
					key: 'supplierName',
					label: this.FormMSG(221, 'Supplier'),
					formatter: (v) => v.charAt(0).toUpperCase() + v.slice(1).toLowerCase(),
					sortable: true,
					class: 'text-left'
				},
				{
					key: 'fullName',
					label: this.FormMSG(231, 'Full Name'),
					formatter: (v) => v.charAt(0).toUpperCase() + v.slice(1).toLowerCase(),
					sortable: true,
					class: 'text-left'
				},
				{
					key: 'departmentName',
					label: this.FormMSG(2410, 'Department'),
					formatter: (v) => v.charAt(0).toUpperCase() + v.slice(1).toLowerCase(),
					sortable: true,
					class: 'text-left'
				},
				{
					key: 'functionName',
					label: this.FormMSG(2010, 'Function'),
					formatter: (v) => v.charAt(0).toUpperCase() + v.slice(1).toLowerCase(),
					sortable: true,
					class: 'text-left'
				},
				{
					key: 'validatedStatus',
					label: this.FormMSG(24, 'Status'),
					sortable: true,
					class: 'text-center'
				},
				{
					key: 'amount',
					label: this.FormMSG(25, 'Amount'),
					formatter: (value) => {
						return this.displayCurrency(value);
					},
					sortable: true,
					class: 'text-right'
				}
			];
		}
	},
	created() {
		this.caption1 = this.FormMSG(1, 'Show filters');
	},
	methods: {
		activeFilter(keyword) {
			this.haveActiveFilter = true;
			this.$emit('activate-filter-by-keyword', keyword);
		},
		onHandleInitFilter() {
			this.filter = '';
			this.haveActiveFilter = false;
			this.$emit('activate-filter-by-keyword', null);
		},
		async handleChangeProcess(payload, item, index) {
			await changeStatusOfProcess(+item.id, 3, payload);
		},
		showPicture(images) {
			if (isNil(images)) return;

			const list = images.map((img) => ({
				xid: img,
				src: `${process.env.VUE_APP_GQL}/images/${img}`,
				thumb: process.env.VUE_APP_PATH_IMG_THUMB_URL + img,
				ext: getFileExtension(img)
			}));

			this.$previewImages({
				images: list,
				focusIndex: 0,
				options: {
					hideLikeDislike: true,
					hideCommentButton: true,
					hideDisLikeButton: true,
					presentationMode: 'doc'
				}
			});
		},
		handleClickLoadMore({ offset, limit, perPage }) {
			this.offset = offset;
			this.limit = limit;
			this.perPage = perPage;

			this.$emit('budget-salaries:load-more', {
				offset,
				limit,
				perPage
			});
		},
		goToDetail(item) {
			store.setCurPO(item);
			this.$router.push({ path: `/budget/purchase-order/${item.id.toString()}?PO=1` });
		},
		displayCurrency(value) {
			return rendCurrency(value);
		},
		displayKgCo2(value) {
			return rendKgCo2(value);
		},
		async handleFilterBudgetAdvancedChange(payload) {
			this.emitLoading(true);
			this.expenses = [];
			const result = await getProjectUserPoDetail({ ...payload, groupBySheets: true });

			const arr = [];
			const data = result;
			for (const item of data) {
				let PO = item.purchaseOrder;
				PO.fullName = PO.user.name + ' ' + PO.user.firstName;
				PO.statusClass = validatedColor(PO.validated);
				PO.validatedStatus = await validatedText(PO.validated);
				arr.push(PO);
			}
			this.expenses = arr;
			this.emitLoading(false);
		},
		emitLoading(value) {
			this.$emit('budget-po:loading', value);
		},
		async handleFilterBudgetAdvancedClickSelection(oPayload) {
			this.emitLoading(true);
			const payload = _.omit(oPayload, this.removeFromReportKeys);

			this.scripts = await getScripts(store.state.myProject.licenseID, store.state.myProject.id, this.scriptType, true);

			let script = { id: 0, fileName: null, ext: 'xlsx' };

			if (this.scripts.length > 1) {
				this.customReport = payload;
				this.isScriptPoReportOpen = true;
				this.emitLoading(false);
			} else {
				this.customReport = null;

				if (this.scripts.length === 1) {
					script = {
						id: this.scripts[0].id,
						fileName: this.scripts[0].customExportFileName,
						ext: this.getExtensionScript(
							OUTPUT_FORMAT_EXPORT.at(this.scripts[0].outputFileFormat),
							this.scripts[0].customExtension,
							getFileExtension(this.scripts[0].fileName)
						)
					};
				}

				let fileName = this.getFileNameScript(FILENAME_REPORT.REPORT_PO, script.id, script.fileName);

				await purchaseOrderReport(payload, script.id)
					.then((result) => {
						this.forceDownload(result, fileName + '.' + script.ext);
					})
					.catch((e) => {
						console.error(e);
						this.emitLoading(false);
					})
					.finally(() => {
						this.emitLoading(false);
					});
			}
		},
		poRerpotOnClosed() {
			this.isScriptPoReportOpen = false;
		},
		handleLoading(payload) {
			if (payload) {
				this.isLoadingDownload = false;
			}
		}
	}
};
</script>
